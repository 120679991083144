<template>
  <div
    :class="
      `chat-message tw-pt-4 tw-flex tw-gap-2 ${
        reverse ? 'tw-flex-row-reverse' : 'tw-flex-row'
      }`
    "
  >
    <div
      class="
        tw-flex-none tw-justify-center tw-items-center
        icon
        tw-p-2 tw-h-fit tw-w-min tw-rounded-full
      "
      :class="
        `${reverse ? 'tw-bg-slate-200 tw-drop-shadow-sm' : 'tw-bg-slate-200'}`
      "
    >
      <svg
        v-if="reverse"
        class="tw-w-5 tw-h-5"
        viewBox="0 0 50 28"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>mobile</title>
        <desc>Created with Sketch.</desc>
        <defs></defs>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="mobile">
            <path
              d="M29.3980392,0.0965 C27.2504902,0.0965 25.7686275,1.4815 24.8519608,3.407 L18.8186275,16.385 L18.8107843,16.3655 L18.8068627,16.3735 L18.8034314,16.3655 L18.7931373,16.385 L12.7607843,3.4075 C11.845098,1.4815 10.3632353,0.097 8.21617647,0.097 L0.600980392,0.097 C0.324509804,0.097 0.0965686275,0.328 0.0965686275,0.611 C0.0965686275,0.8935 0.32254902,1.125 0.600980392,1.125 L0.866176471,1.125 L1.10294118,1.125 C2.19656863,1.156 3.13088235,1.699 3.92696078,3.407 L14.3308824,25.7135 C13.9122549,26.2205 13.3671569,26.5205 12.4338235,26.599 L11.3642157,26.599 C11.0867647,26.599 10.8598039,26.831 10.8598039,27.1135 C10.8598039,27.3975 11.0867647,27.6295 11.3642157,27.6295 L19.3544118,27.6295 C21.8602941,27.6415 23.4553922,25.3875 24.4098039,23.3385 L33.6857843,3.407 C34.4813725,1.698 35.4166667,1.156 36.5117647,1.125 L36.7470588,1.125 L37.0127451,1.125 C37.2901961,1.125 37.5176471,0.8935 37.5176471,0.611 C37.5176471,0.328 37.2901961,0.097 37.0127451,0.097 L29.3980392,0.097 L29.3980392,0.0965 Z"
              id="Shape"
              fill="#2D2B74"
            ></path>
            <path
              d="M41.0843137,0.0965 C38.9323529,0.0965 37.4558824,1.198 36.5397059,3.124 L26.3357843,23.939 C25.5343137,25.5755 25.127451,26.599 23.147549,26.599 C22.4813725,26.599 22.4813725,27.627 23.147549,27.63 L35.9637255,27.63 C42.5392157,27.6485 46.3323529,23.675 46.979902,18.839 C47.1779412,17.3615 47.0122549,16.886 45.6529412,18.0845 C43.3696078,20.0995 40.3691176,21.6135 38.4387255,21.7655 C37.5093137,21.8385 37.804902,21.217 38.0421569,20.707 L46.095098,3.407 C46.720098,2.0625 47.5759804,1.125 49.0843137,1.125 C49.7514706,1.125 49.7504902,0.101 49.0843137,0.0965 L41.0843137,0.0965 L41.0843137,0.0965 Z M18.8073529,0.0965 C21.0735294,0.0965 22.9107843,1.9715 22.9107843,4.2815 C22.9107843,6.5945 21.0735294,8.4695 18.8073529,8.4695 C16.5401961,8.4695 14.7019608,6.5945 14.7019608,4.2815 C14.7019608,1.9715 16.5401961,0.0965 18.8073529,0.0965 L18.8073529,0.0965 Z"
              id="Shape"
              fill="#C72127"
            ></path>
          </g>
        </g>
      </svg>
      <div class="tw-text-white tw-font-bold tw-w-5 tw-h-5 tw-uppercase" v-else>
        {{ senderName[0] }}
      </div>
    </div>
    <div
      v-if="message.Type === 'text'"
      class="
        message
        tw-flex
        tw-max-w-[20rem]
        tw-h-fit
        tw-py-2
        tw-text-left
        tw-items-center
        tw-relative
        tw-px-4
        tw-rounded-lg
      "
      :class="
        `${
          reverse
            ? `tw-bg-${messageColor.bg} tw-text-${messageColor.text}`
            : 'tw-bg-white tw-border tw-border-gray-200'
        } ${showTimestamp ? 'tw-mb-4' : ''}`
      "
      @click="showTimestamp = !showTimestamp"
    >
      <div
        class="
          tw-absolute tw--top-5 tw-text-xs tw-text-gray-400 tw-whitespace-nowrap
        "
        :class="{
          'tw-right-0': reverse,
          'tw-left-0': !reverse,
        }"
      >
        {{ senderName.split(" ")[0] }}
      </div>
      <p class="tw-whitespace-pre-wrap">{{ message.Text }}</p>
      <p
        v-if="showTimestamp"
        class="
          tw-text-xs
          tw-text-slate-700
          tw-absolute
          tw--bottom-5
          tw-whitespace-nowrap
        "
        :class="reverse ? 'tw-right-0' : 'tw-left-0'"
      >
        {{ message.CreatedAt | formatedTimestamp }}
      </p>
    </div>
    <div
      v-if="message.Type === 'image'"
      class="
        message
        tw-flex
        tw-max-w-[20rem]
        tw-h-fit
        tw-p-2
        tw-text-left
        tw-items-center
        tw-relative
        tw-rounded-lg
        tw-text-slate-800
        tw-shadow-sm
        tw-drop-shadow-sm
        tw-border
      "
      :class="
        `${
          reverse
            ? `tw-bg-${messageColor.bg} tw-text-${messageColor.text}`
            : 'tw-bg-white'
        } ${showTimestamp ? 'tw-mb-4' : ''}`
      "
      @click="showTimestamp = !showTimestamp"
    >
      <div
        class="
          tw-absolute tw--top-5 tw-text-xs tw-text-gray-400 tw-whitespace-nowrap
        "
        :class="{
          'tw-right-0': reverse,
          'tw-left-0': !reverse,
        }"
      >
        {{ senderName.split(" ")[0] }}
      </div>
      <img class="tw-w-full" :src="imageURL" alt="chat img" />
      <p
        v-if="showTimestamp"
        class="
          tw-text-xs
          tw-text-slate-700
          tw-absolute
          tw--bottom-5
          tw-whitespace-nowrap
        "
        :class="reverse ? 'tw-right-0' : 'tw-left-0'"
      >
        {{ message.CreatedAt | formatedTimestamp }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

export default {
  name: "ChatMessage",
  props: ["message"],
  data() {
    return {
      showTimestamp: false,
      imageURL: null,
      messageColor: {
        text: "white",
        bg: "primary",
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "userInfo",
      decoded: "chat/decoded",
      agents: "chat/agents",
      channel: "chat/channel",
      room: "chat/room",
    }),
    // senderID() {
    //   if (this.user) {
    //     return this.user.attributes.sub;
    //   }
    //   return null;
    // },
    participants() {
      if (this.channel === "background") {
        return this.room.BackgroundChannel.Participants;
      }
      return this.room.MainChannel.Participants;
    },
    senderName() {
      const senderName = this.participants.find(
        (p) => p.ID === this.message.SenderID
      )?.Name;
      if (senderName) {
        return senderName;
      } else if (this.message.SenderInfo?.Name) {
        return this.message.SenderInfo?.Name;
      }
      return "Unknown";
    },
    reverse() {
      if (this.channel === "main") {
        const agent = this.agents.find(
          (a) => a.AgentID === this.message.SenderID
        );
        if (agent) {
          return true;
        }
      }
      return this.message?.SenderID === this.user.userCode;
    },
  },
  watch: {
    message: {
      immediate: true,
      handler: async function () {
        // console.log(this.message);
        if (this.message.Type === "image") {
          //Preview type
          if (this.message.imageSrc) {
            this.imageURL = this.message.imageSrc;
            return;
          }
          this.imageURL = await this.$store.dispatch(
            "chat/getImageURL",
            this.message.File
          );
          return;
        }
        this.imageURL = null;
      },
    },
  },
  methods: {},
  filters: {
    formatedTimestamp(timestamp) {
      if (dayjs(timestamp).isSame(dayjs(), "day")) {
        return dayjs(timestamp).format("LT");
      }
      return dayjs(timestamp).format("llll");
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}
</style>
